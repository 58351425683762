import './App.scss'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Welcome to <h3>Simple 2 Master</h3>
      </header>
    </div>
  );
}

export default App;
